import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { hideContextMenu, setPersistantItem, removePersistantItem, setState, disableSort } from '../actions/UIActions';
import { removeActiveItem, fetchDriveStructure, setLoading } from '../actions/driveActions';

import Header from '../components/common/header';
import Portal from '../components/common/portal';
import ContextMenu from '../components/drive/contextMenu';

import Popup from '../components/popup/popup';
import Drive from '../components/drive/drive';
import { hasPermission } from '../utils/permissions';
import { removeWindowDragClasses, windowOut } from '../utils/upload';

class PageDrive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionMenu: false,
      isRootFolder: true,
      isQuickAccessFolder: false,
    };

    this.refToggler = React.createRef();
    this.refControls = React.createRef();
  }

  handleClickOut = (e) => {
    const { dispatch, drive } = this.props;

    const contextMenu = document.querySelector('#CONTEXT_MENU');
    const onDetailPanelToggler =
      e.target.classList.contains('drive-panel') || e.target.closest('.header__icon--details');
    const onInfoPanel = e.target.classList.contains('drive-panel') || e.target.closest('#DRIVE_PANEL');
    const onItem = e.target.classList.contains('item') || e.target.closest('.item');
    const popup = document.querySelector('#POPUP');

    // CLICK INTO CONTEXT MENU
    if (contextMenu && contextMenu.contains(e.target)) {
      dispatch(setPersistantItem());
      return false;
    }

    if (onDetailPanelToggler || onItem) {
      return false;
    }

    // pokud je context menu ale klikám mimo něj
    if (contextMenu && !contextMenu.contains(e.target)) {
      dispatch(hideContextMenu());
    }

    // pokud nemám popup a poté neklikám ani na drive-item nebo do drive-item
    if (drive.activeItem && !popup && !onItem && !onInfoPanel) {
      dispatch(removeActiveItem());
      dispatch(removePersistantItem());
    }
  };

  handleResize = () => {
    const { dispatch, UI } = this.props;
    if (UI?.contextMenu?.show) {
      dispatch(hideContextMenu());
    }
  };

  handleDragIn = () => {
    document.body.classList.add('is-dragging');
    if (this.props.UI.sorting) document.body.classList.add('is-sorting');
    if (this.state.isRootFolder) document.body.classList.add('is-root-folder');
    if (this.state.isQuickAccessFolder) document.body.classList.add('is-quick_access-folder');
  };

  handleDragOut = (e) => {
    e.preventDefault();
    if (e.type === 'dragover') return;
    if (e.type === 'drop') removeWindowDragClasses();

    if (e.type !== 'dragleave' || e.type === 'dragend') removeWindowDragClasses();
    if (e.type === 'dragend') this.props.dispatch(disableSort());
    windowOut(e);
  };

  showPopup = (type) => {
    this.setState({ actionMenu: false });
    this.props.dispatch(setState({ showPopup: type }));
  };

  toggleActionMenu = () => {
    this.setState({ actionMenu: !this.state.actionMenu });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params } = this.props.match;
    nextProps.match.params.id !== params.id && this.fetchData(nextProps.match.params.id);
  }

  componentDidMount() {
    const { match } = this.props;
    const { refToggler: ref1, refControls: ref2 } = this;

    this.fetchData(match.params.id);

    document.addEventListener('click', (e) => {
      if (ref1?.current && ref2?.current && !ref1.current.contains(e.target) && !ref2.current.contains(e.target)) {
        this.setState({ actionMenu: false });
      }
    });

    window.addEventListener('dragenter', () => this.handleDragIn());
    window.addEventListener('dragover', (e) => this.handleDragOut(e));
    window.addEventListener('dragleave', (e) => this.handleDragOut(e));
    window.addEventListener('dragend', (e) => this.handleDragOut(e));
    window.addEventListener('drop', (e) => this.handleDragOut(e));

    document.addEventListener('mousedown', (e) => this.handleClickOut(e));
    window.addEventListener('resize', () => this.handleResize());
  }

  componentWillUnmount() {
    window.removeEventListener('dragenter', () => this.handleDragIn());
    window.removeEventListener('dragleave', (e) => this.handleDragOut(e));
    window.removeEventListener('dragend', (e) => this.handleDragOut(e));

    document.removeEventListener('mousedown', (e) => this.handleClickOut(e));
    window.removeEventListener('resize', () => this.handleResize());
  }

  fetchData(id) {
    const { dispatch } = this.props;

    this.setState({
      isRootFolder: id ? false : true,
      isQuickAccessFolder: id === 'quick-access' ? true : false,
    });

    dispatch(setLoading());
    dispatch(fetchDriveStructure(id));
  }

  render() {
    const { actionMenu, isRootFolder, isQuickAccessFolder } = this.state;
    const { folders, files, loading: isLoading, share, permissions } = this.props.drive;
    const { contextMenu, showPopup } = this.props.UI;

    let canCreate = !permissions || permissions.length === 0 ? true : hasPermission(permissions, 'create');

    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <Drive
            folders={folders}
            files={files}
            shared={share}
            loading={isLoading}
            canCreate={canCreate}
            isRoot={isRootFolder}
            isQuickAccess={isQuickAccessFolder}
          />
        </div>

        {canCreate && !isQuickAccessFolder && (
          <>
            <div className="action-bar" ref={this.refToggler} onClick={() => this.toggleActionMenu()}></div>
            {actionMenu && (
              <div className="action-items" ref={this.refControls}>
                {!isRootFolder && (
                  <>
                    <div className="action-item" onClick={(e) => this.showPopup('uploadFile')}>
                      Nahrát soubor
                    </div>
                    <div className="action-item" onClick={(e) => this.showPopup('createTable')}>
                      Vytvořit novou tabulku
                    </div>
                    <div className="action-item" onClick={(e) => this.showPopup('createDocument')}>
                      Vytvořit nový dokument
                    </div>
                  </>
                )}
                <div className="action-item" onClick={(e) => this.showPopup('createFolder')}>
                  Vytvořit složku
                </div>
              </div>
            )}
          </>
        )}

        <Portal>
          {contextMenu.show && <ContextMenu canCreate={canCreate} isQuickAccessFolder={isQuickAccessFolder} />}
          <Popup type={showPopup} />
        </Portal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(PageDrive);
