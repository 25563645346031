import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { Button } from '../../common/button';
import PageLoading from '../../common/pageLoading';

import { fetchDriveStructure, setLoading, getFoldersList, moveItems } from '../../../actions/driveActions';
import { hasPermission } from '../../../utils/permissions';
import { saveScrollPosition, setScrollPosition } from '../../../utils/windowScrollPosition';

class Move extends Component {
  state = {
    isLoading: true,
    selectedItem: false,
    data: {
      path: [],
      share: [],
      folders: [],
    },
  };

  componentDidMount() {
    this.handleChangeFolder();
  }

  handleChangeFolder(folderId) {
    this.setState({ isLoading: true, selectedItem: false });

    const isFile = this?.props?.drive?.activeItem?.type === 'file' ? true : false;

    getFoldersList(folderId).then((response) => {
      if (response.path.length === 1 && !isFile)
        response.folders = [{ id: 'root', name: 'Kořenová složka', rootFolder: true }, ...response.folders];
      this.setState({
        isLoading: false,
        data: response,
      });
    });
  }

  handleSelectFolder(folderId) {
    this.setState({
      selectedItem: folderId,
    });
  }

  handleSubmit = () => {
    const { dispatch, match, handleClose, drive } = this.props;

    let items = drive?.activeItem;
    let target = this.state.selectedItem;

    moveItems(items, target).then(() => {
      saveScrollPosition();

      dispatch(setLoading());
      handleClose();
      dispatch(fetchDriveStructure(match.params.id)).then(() => {
        setScrollPosition();
      });
    });
  };

  render() {
    const { isLoading, data, selectedItem } = this.state;

    const listFolders = data.folders.map((item) => (
      <div
        className={`list-tree__item ${selectedItem === item.id ? 'is-selected' : ''}`}
        key={item.id}
        onClick={() => this.handleSelectFolder(item.id)}
      >
        {item.name}
        {!item.rootFolder && (
          <span className="list-tree__arrow" onClick={() => this.handleChangeFolder(item.id)}></span>
        )}
      </div>
    ));

    const listFoldersShare = data.share.map((item) => (
      <div
        className={`list-tree__item ${hasPermission(item.permissions, 'owner') ? '' : 'is-disabled'} ${
          selectedItem === item.id ? 'is-selected' : ''
        }`}
        key={item.id}
        onClick={hasPermission(item.permissions, 'owner') ? () => this.handleSelectFolder(item.id) : null}
      >
        {item.name}
        {hasPermission(item.permissions, 'owner') && (
          <span className="list-tree__arrow" onClick={() => this.handleChangeFolder(item.id)}></span>
        )}
      </div>
    ));

    const breadcrumb = data.path.map((item) => (
      <Fragment key={item.id.toString()}>
        {item.name === '/' ? (
          <div className="breadcrumb__item breadcrumb__item--home" onClick={() => this.handleChangeFolder()}></div>
        ) : (
          <div className="breadcrumb__item" onClick={() => this.handleChangeFolder(item.id)}>
            {item.name}
          </div>
        )}
        <span className="breadcrumb__item-divider">/</span>
      </Fragment>
    ));

    if (isLoading) return <PageLoading />;

    return (
      <>
        {breadcrumb.length > 1 && <div className="list-tree-breadcrumb breadcrumb">{breadcrumb}</div>}

        <div className="list-tree">
          {listFoldersShare.length > 0 && (
            <div>
              <div className="list-tree__title mb-1">Sdílené složky:</div>
              {listFoldersShare}
            </div>
          )}
          {listFolders.length > 0 && (
            <div>
              <div className={`list-tree__title mb-1 ${listFoldersShare.length > 0 ? 'mt-3' : ''}`}>Složky:</div>
              {listFolders}
            </div>
          )}
        </div>

        {listFoldersShare.length === 0 && listFolders.length === 0 && (
          <div className="empty-state text-small text-center">
            V této složce nemáte žádné podsložky kam položky přesunout.
          </div>
        )}

        <div className="btn-group btn-group--right mt-3">
          <Button type="primary" disabled={!selectedItem} onClick={() => this.handleSubmit()}>
            Přesunout
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Move);
