import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import Item from './driveItem';
import { showContextMenu } from '../../actions/UIActions';
import { onDrop, windowOut } from '../../utils/upload';

class DriveWrapper extends Component {
  state = {
    isDragOver: false,
  };

  createItems = (items, type) => {
    const { mode } = this.props;
    let result = [];

    if (items) {
      result = Object.keys(items).map((key) => {
        return <Item key={key} id={items[key].id} item={items[key]} type={type} mode={mode} />;
      });
    }

    return result;
  };

  handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch } = this.props;

    dispatch(
      showContextMenu({
        item: false,
        x: e.clientX,
        y: e.clientY,
      })
    );
  };

  onDragEnter = () => {
    this.setState({ isDragOver: true });
  };

  onDragOver = (e) => {
    e.preventDefault();

    if (e.target.classList.contains('item--folder') || e.target.closest('item--folder')) {
      this.setState({ isDragOver: false });
    } else {
      this.setState({ isDragOver: true });
    }
  };

  onDragLeave = (e) => {
    if (e.target.classList.contains('drive-wrapper')) this.setState({ isDragOver: false });
    if (windowOut(e) === true) this.setState({ isDragOver: false });
    const onItem = !e.target.classList.contains('item--folder') || !e.target.closest('item--folder');

    if (onItem) return;
    this.setState({ isDragOver: false });
  };

  onDrop = (e) => {
    onDrop(this, e);
  };

  render() {
    const { UI, drive, isRoot: isRootFolder } = this.props;

    const view = UI.driveTypeGrid ? 'grid' : 'list';

    const folders = this.createItems(drive.folders, 'folder');
    const files = this.createItems(drive.files, 'file');

    let sharedFolders = [];
    let sharedFiles = [];
    if (drive.share) {
      sharedFolders = this.createItems(drive.share.folders, 'folder');
      sharedFiles = this.createItems(drive.share.files, 'file');
    }

    let hasShared = sharedFolders.length + sharedFiles.length;
    let isEmpty = folders.length + files.length + sharedFolders.length + sharedFiles.length === 0 ? true : false;

    return (
      <div
        className={`drive-wrapper drive-wrapper--${view} ${isEmpty ? 'drive-wrapper--empty' : ''} ${
          this.state.isDragOver ? 'drive-wrapper--dragging' : ''
        }`}
        onContextMenu={(e) => this.handleContextMenu(e)}
        onDragOver={!isRootFolder ? (e) => this.onDragOver(e) : null}
        onDragEnter={!isRootFolder ? (e) => this.onDragEnter(e) : null}
        onDragLeave={!isRootFolder ? (e) => this.onDragLeave(e) : null}
        onDrop={!isRootFolder ? (e) => this.onDrop(e) : null}
      >
        {isEmpty && <span className="empty-state">V této složce zatím nemáte žádné soubory ani složky.</span>}
        {hasShared > 0 && (
          <div className="drive-group">
            <div className="drive-group-title">
              Sdílené
              {view === 'list' && (
                <div className="item-additions">
                  <span className="item-additions__date">Vytvořeno</span>
                  <span className="item-additions__date">Upraveno</span>
                </div>
              )}
            </div>
            <div className="drive-grid">
              {sharedFolders} {sharedFiles}
            </div>
          </div>
        )}

        {folders.length > 0 && (
          <div className="drive-group">
            <div className="drive-group-title">
              Složky
              {view === 'list' && (
                <div className="item-additions">
                  <span className="item-additions__date">Vytvořeno</span>
                  <span className="item-additions__date">Upraveno</span>
                </div>
              )}
            </div>
            <div className="drive-grid">{folders}</div>
          </div>
        )}

        {files.length > 0 && (
          <div className="drive-group">
            <div className="drive-group-title">
              Soubory
              {view === 'list' && (
                <div className="item-additions">
                  <span className="item-additions__date">Vytvořeno</span>
                  <span className="item-additions__date">Upraveno</span>
                </div>
              )}
            </div>
            <div className="drive-grid">{files}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  withRouter,
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(DriveWrapper);
