import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

// components
import DriveWrapper from './driveWrapper';
import DetailsPanel from './detailsPanel';
import PageLoading from '../common/pageLoading';

class Drive extends Component {
  render() {
    const { drive, loading, share, mode, canCreate, isRoot, isQuickAccess } = this.props;

    if (loading) {
      return <PageLoading />;
    }

    return (
      <div className="drive">
        <div className="drive-items">
          <DriveWrapper
            shared={share}
            folders={drive.folders}
            files={drive.files}
            mode={mode}
            canCreate={canCreate}
            isRoot={isRoot}
            isQuickAccess={isQuickAccess}
          />
        </div>
        <DetailsPanel />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Drive);
