import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { removePersistantItem, setPersistantItem, hideContextMenu } from '../actions/UIActions';
import { removeActiveItem, fetchDriveStructureAnonymously, setLoading } from '../actions/driveActions';

import Header from '../components/common/header';
import Portal from '../components/common/portal';
import ContextMenu from '../components/drive/contextMenu';
import Drive from '../components/drive/drive';
import Popup from '../components/popup/popup';
import { logout } from '../actions/authActions';

class PageDrive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionMenu: false,
    };

    this.refToggler = React.createRef();
    this.refControls = React.createRef();
  }

  handleClickOut = (e) => {
    const { dispatch, drive } = this.props;

    const onInfoPanel = e.target.classList.contains('drive-panel') || e.target.closest('#DRIVE_PANEL');
    const popup = document.querySelector('#POPUP');
    const onItem = e.target.classList.contains('.drive-item') || e.target.closest('.drive-item');

    // pokud nemám popup a poté neklikám ani na drive-item nebo do drive-item
    if (drive.activeItem && !popup && !onItem && !onInfoPanel) {
      dispatch(removeActiveItem());
      dispatch(removePersistantItem());
    }
  };

  handleClickOut = (e) => {
    const { dispatch, drive } = this.props;

    const contextMenu = document.querySelector('#CONTEXT_MENU');
    const onDetailPanelToggler =
      e.target.classList.contains('drive-panel') || e.target.closest('.header__icon--details');
    const onInfoPanel = e.target.classList.contains('drive-panel') || e.target.closest('#DRIVE_PANEL');
    const onItem = e.target.classList.contains('item') || e.target.closest('.item');
    const popup = document.querySelector('#POPUP');

    // CLICK INTO CONTEXT MENU
    if (contextMenu && contextMenu.contains(e.target)) {
      dispatch(setPersistantItem());
      return false;
    }

    if (onDetailPanelToggler || onItem) {
      return false;
    }

    // pokud je context menu ale klikám mimo něj
    if (contextMenu && !contextMenu.contains(e.target)) {
      dispatch(hideContextMenu());
    }

    // pokud nemám popup a poté neklikám ani na drive-item nebo do drive-item
    if (drive.activeItem && !popup && !onItem && !onInfoPanel) {
      dispatch(removeActiveItem());
      dispatch(removePersistantItem());
    }
  };

  handleResize = () => {
    const { dispatch, UI } = this.props;
    if (UI?.contextMenu?.show) {
      dispatch(hideContextMenu());
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params } = this.props.match;
    if (nextProps.match.params.id !== params.id) {
      this.fetchData(nextProps.match.params.id, nextProps.match.params.token);
    }
  }

  componentDidMount() {
    const { refToggler: ref1, refControls: ref2 } = this;

    const { dispatch } = this.props;
    dispatch(logout());

    document.addEventListener('click', (e) => {
      if (
        ref1 &&
        ref1.current &&
        ref2 &&
        ref2.current &&
        !ref1.current.contains(e.target) &&
        !ref2.current.contains(e.target)
      ) {
        this.setState({
          actionMenu: false,
        });
      }
    });

    this.fetchData(this.props.match.params.id, this.props.match.params.token);
    document.addEventListener('mousedown', (e) => this.handleClickOut(e));
    window.addEventListener('resize', () => this.handleResize());
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (e) => this.handleClickOut(e));
    window.removeEventListener('resize', () => this.handleResize());
  }

  fetchData(id, token) {
    const { dispatch } = this.props;
    dispatch(setLoading());
    dispatch(fetchDriveStructureAnonymously(id, token));
  }

  render() {
    const { folders, files, loading: isLoading, share } = this.props.drive;
    const { showPopup, contextMenu } = this.props.UI;

    return (
      <Fragment>
        <Header mode="view" />
        <div className="page-content">
          <Drive folders={folders} files={files} shared={share} loading={isLoading} mode="view" />
        </div>

        <Portal>
          {contextMenu.show && <ContextMenu mode="view" />}
          <Popup type={showPopup} />
        </Portal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(PageDrive);
