export const fileSize = (size) => {
  let i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const hasOnlyOffice = (item) => {
  const extension = item?.details?.extension?.toLowerCase();
  const previewExtensions = ['doc', 'docx', 'xls', 'xlsx'];
  return previewExtensions.includes(extension);
};

export const hasPreview = (item) => {
  const extension = item?.details?.extension?.toLowerCase();
  const previewExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
  return previewExtensions.includes(extension);
};
