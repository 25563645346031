import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { InputFile } from '../../common/input';
import { Button } from '../../common/button';

import { createNotification } from '../../../actions/UIActions';
import { fetchDriveStructure, setLoading } from '../../../actions/driveActions';
import Axios from 'axios';
import { saveScrollPosition, setScrollPosition } from '../../../utils/windowScrollPosition';

class UploadFolderImage extends Component {
  formSubmit = (e) => {
    e.preventDefault();
    const { dispatch, match, handleClose, drive } = this.props;

    const data = new FormData(e.target); // data from form

    Axios.post(`folder/${drive.activeItem.id}/image`, data)
      .then((res) => {
        saveScrollPosition();

        dispatch(setLoading());
        handleClose();
        dispatch(fetchDriveStructure(match.params.id)).then(() => {
          setScrollPosition();
        });
        dispatch(
          createNotification({
            type: 'success',
            message: 'Obrázek složky byl úspěšně nahrán',
          })
        );
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Nahrávání se nepodařilo, zkuste prosím znovu',
          })
        );
      });
  };

  render() {
    const { t, handleClose } = this.props;

    return (
      <form className="form" onSubmit={this.formSubmit} encType="multipart/form-data">
        <InputFile name="image" />
        <div className="btn-group btn-group--right">
          <Button type="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button type="primary" formAction="submit">
            {t('save')}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  withRouter,
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(UploadFolderImage);
