import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { getValues } from '../../../utils/form';
import { InputHidden } from '../../common/input';
import { Button } from '../../common/button';

import { removeFolder, removeFile, fetchDriveStructure, setLoading } from '../../../actions/driveActions';
import { saveScrollPosition, setScrollPosition } from '../../../utils/windowScrollPosition';

class RemoveFolder extends Component {
  formSubmit = (e) => {
    e.preventDefault();
    const { dispatch, handleClose, match, drive } = this.props;
    const data = getValues(e.target);

    if (drive && drive.activeItem && drive.activeItem && drive.activeItem.type) {
      dispatch(removeFile(data.id)).then(() => {
        saveScrollPosition();

        dispatch(setLoading());
        handleClose();
        dispatch(fetchDriveStructure(match.params.id)).then(() => {
          setScrollPosition();
        });
      });
    } else {
      dispatch(removeFolder(data.id)).then(() => {
        saveScrollPosition();

        dispatch(setLoading());
        handleClose();
        dispatch(fetchDriveStructure(match.params.id)).then(() => {
          setScrollPosition();
        });
      });
    }
  };

  render() {
    const { handleClose } = this.props;
    const { id, name } = this.props.drive.activeItem;

    return (
      <form className="form" onSubmit={this.formSubmit}>
        <InputHidden name="id" value={id} />
        <div className="confirm">
          Opravdu chcete odstranit položku <strong>{name}</strong>.
          <div className="btn-group">
            <Button type="secondary" onClick={handleClose}>
              Ne, nemazat
            </Button>
            <Button type="danger" formAction="submit">
              Ano, smazat
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  withRouter,
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(RemoveFolder);
