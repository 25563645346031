export const getPermission = (permissions, name) => {
  let result = permissions.find((perm) => perm.name === 'owner');

  if (result === undefined) {
    result = permissions.find((perm) => perm.name === name);
  }

  return result;
};

export const hasPermission = (permissions, name) => {
  let result = permissions.find((perm) => perm.name === 'owner');

  if (result === undefined) {
    result = permissions.find((perm) => perm.name === name);
  }

  if (result) {
    return true;
  }

  return false;
};

export const isOwner = (props, item) => {
  let email = null;
  let owner = null;

  if (props && props.auth && props.auth.user && props.auth.user.email) {
    email = props.auth.user.email;
  }

  if (!item) {
    if (
      props &&
      props.drive &&
      props.drive.activeItem &&
      props.drive.activeItem.details &&
      props.drive.activeItem.details.owner &&
      props.drive.activeItem.details.owner.email
    ) {
      owner = props.drive.activeItem.details.owner.email;
    }
  } else {
    if (item.details && item.details.owner && item.details.owner.email) {
      owner = item.details.owner.email;
    }
  }

  if (email !== null && owner !== null && email === owner) {
    return true;
  }

  return false;
};
