import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { InputText, InputCheckbox } from '../components/common/input';
import { Button } from '../components/common/button';

import { getValues } from '../utils/form';
import { login } from '../actions/authActions';

class PageLogin extends Component {
  state = {
    loading: false,
    loginError: false,
  };

  hasError = () => {
    this.setState({
      loading: false,
      loginError: true,
    });

    setTimeout(() => {
      this.setState({
        loginError: false,
      });
    }, 3500);
  };

  formSubmit = (e) => {
    e.preventDefault();
    const { dispatch, history } = this.props;
    const formValues = getValues(e.target);

    this.setState({
      loading: true,
    });

    dispatch(login(formValues))
      .then(() => {
        history.push('/');
      })
      .catch(() => {
        this.hasError();
      });
  };

  render() {
    const { t } = this.props;
    const { loading, loginError } = this.state;

    return (
      <div className="page-grid page-grid--login">
        <div className="login">
          <div className="login-inner">
            <h2 className="mb-1">{t('pageLogin.title')}</h2>
            <p className="text-secondary mt-0 mb-2">{t('pageLogin.subtitleText')}</p>
            <form className="form" onSubmit={(e) => this.formSubmit(e)}>
              {loginError && <div className="form-error">{t('pageLogin.formLoginError')}</div>}
              <InputText required type="email" name="email" label={t('pageLogin.email')} autoComplete="email" />
              <InputText
                required
                type="password"
                name="password"
                label={t('pageLogin.password')}
                autoComplete="current-password"
              />
              <InputCheckbox name="permanent" label={t('pageLogin.remember')} />
              <div className="btn-group btn-group--right">
                <Button type="primary" loading={loading} formAction="submit">
                  {t('pageLogin.submit')}
                </Button>
              </div>
              <span className="text-secondary text-small mt-2">
                {t('pageLogin.forgottenPassword')} <Link to="/reset-password">{t('pageLogin.clickHere')}</Link>
              </span>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(PageLogin);
