import React from 'react';

export function InputText(props) {
  let { name, id, placeholder, label, value: defaultValue, type, required, innerRef, ...rest } = props;

  if (!type) {
    type = 'text';
  }

  if (!id) {
    id = Math.random().toString(36).substr(2, 9);
  }

  return (
    <div className={`field field--${type}`}>
      <div className="field-wrapper">
        <input
          className="field-control"
          required={required}
          ref={innerRef}
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          defaultValue={defaultValue}
          {...rest}
        />
        <label className="field-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
}

export function InputCheckbox(props) {
  let { name, id, label, checked, children, ...rest } = props;

  if (!id) {
    id = Math.random().toString(36).substr(2, 9);
  }

  return (
    <div className="field field--checkbox">
      <div className="field-wrapper">
        <input className="field-control" type="checkbox" id={id} name={name} defaultChecked={checked} {...rest} />
        <label className="field-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
}

export function InputRadio(props) {
  let { name, id, label, checked, children, ...rest } = props;

  if (!id) {
    id = Math.random().toString(36).substr(2, 9);
  }

  return (
    <div className="field field--radio">
      <div className="field-wrapper">
        <input className="field-control" type="radio" id={id} name={name} checked={checked} {...rest} />
        <label className="field-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
}

export function InputFile(props) {
  let { name, id, placeholder, label, required, ...rest } = props;

  if (!id) {
    id = Math.random().toString(36).substr(2, 9);
  }

  return (
    <div className={`field field--file`}>
      <div className="field-wrapper">
        <input
          className="field-control"
          required={required}
          type="file"
          id={id}
          placeholder={placeholder}
          name={name}
          {...rest}
        />
        <label className="field-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
}

export function InputHidden(props) {
  const { name, value } = props;

  return <input type="hidden" name={name} value={value} />;
}
