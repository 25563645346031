import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import formattedDate from '../../utils/date';
import { fileSize } from '../../utils/file';
import { fetchPermissions } from '../../actions/driveActions';
import { Tooltip } from '../common/tooltip';

class DetailsPanel extends Component {
  state = {
    fileId: null,
    permissions: [],
    users: [],
  };

  componentDidUpdate() {
    const { drive } = this.props;
    const { activeItem: item } = drive;

    if (item) {
      if (this.state.fileId === item.id) {
        return false;
      } else {
        this.setState({
          fileId: item.id,
          permissions: [],
          users: [],
        });
      }

      const type = item.type ? 'file' : 'folder';

      fetchPermissions(item.id, type).then((response) => {
        const permissions = response[0].data.data.permissions;
        const users = response[1].data.data[`share_${type}`];

        this.setState({
          permissions,
          users,
        });
      });
    }
  }

  createPermissionsList() {
    const { t } = this.props;
    const { permissions, users } = this.state;
    let userwithPerm = false;
    let result = (
      <div className="permissions-list">
        {users.map((user) => {
          let userHasPerms = false;
          let result = (
            <div className="permissions-list-row" key={user.id}>
              <div className="permissions-list-username">
                {user.name} {user.surname}
                <br />
                <span>{user.email}</span>
              </div>
              <div className="permissions-list-permissions">
                {user.permissions.map((permission) => {
                  let name = permissions.find((perm) => perm.id === permission.id).name;
                  if (permission.have) {
                    userHasPerms = true;
                    userwithPerm = true;
                    return (
                      <Tooltip content={t(`permissions.${name}`)} key={permission.id}>
                        <div className={`permissions-list-permission permissions-list-permission--${name}`}></div>
                      </Tooltip>
                    );
                  } else {
                    return <div className={`permissions-list-permission`} key={permission.id}></div>;
                  }
                })}
              </div>
            </div>
          );

          if (userHasPerms === false) {
            return null;
          }
          return result;
        })}
      </div>
    );

    if (userwithPerm !== true) {
      return null;
    }

    return result;
  }

  render() {
    const { t, drive, UI } = this.props;
    const { showDetailsPanel } = UI;
    const { activeItem } = drive;

    if (!showDetailsPanel) {
      return null;
    }

    if (!activeItem) {
      return (
        <div className="drive-panel" id="DRIVE_PANEL">
          <div className="drive-panel-inner">
            <div className="drive-panel-empty-state">{t(`drivePanel.empty-state`)}</div>
          </div>
        </div>
      );
    } else {
      const { name, details, type } = activeItem;

      let disabledItems = ['image_url', 'file_name', 'type'];
      let detailsItems = Object.keys(details).map((key) => {
        if (disabledItems.includes(key)) return false;
        if (typeof details[key] === 'string' && details[key].trim() === '') return false;

        return (
          <div className={`drive-panel-info drive-panel-info--${key}`} key={key}>
            <span>{t(`drivePanel.${key}`)}:</span>
            <span>
              {typeof details[key] === 'string' && (key === 'date_created' || key === 'date_updated')
                ? formattedDate(details[key])
                : null}
              {typeof details[key] === 'string' && key !== 'date_created' && key !== 'date_updated'
                ? details[key]
                : null}
              {typeof details[key] === 'number' && key === 'size' ? fileSize(details[key]) : null}
              {key === 'owner' ? details[key].email : null}
              {key === 'user_created' ? details[key].email : null}
              {key === 'user_uploaded' ? details[key].email : null}
            </span>
          </div>
        );
      });

      let permissionsList = this.createPermissionsList();

      return (
        <div className="drive-panel" id="DRIVE_PANEL">
          <div className="drive-panel-inner">
            <div className="drive-panel-name">
              {name}
              {type === 'file' && `.${details.extension}`}
            </div>
            {detailsItems}
            {permissionsList && (
              <div className="permissions-list-wrapper">
                <div className="permissions-list-title">{t('permissions.title')}</div>
                {permissionsList}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(DetailsPanel);
