import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { fileSize } from '../../utils/file';
import { toggleStatusBar, hideStatusBar } from '../../actions/statusBarActions';

class StatusBar extends Component {
  constructor(props) {
    super(props);

    this.refToggler = React.createRef();
    this.refWrapper = React.createRef();
  }

  componentDidMount() {
    const { refToggler: ref1, refWrapper: ref2 } = this;

    document.addEventListener('click', (e) => {
      if (
        ref1 &&
        ref1.current &&
        ref2 &&
        ref2.current &&
        !ref1.current.contains(e.target) &&
        !ref2.current.contains(e.target)
      ) {
        this.props.dispatch(hideStatusBar());
      }
    });
  }

  toggleStatusBar = (e) => {
    this.props.dispatch(toggleStatusBar());
  };

  createItemsList = (items) => {
    return Object.keys(items).map((element) => {
      const { loaded, size, itemInfo: info } = items[element];
      let name = (info && info.name) || '';
      let extension = (info && info.extension) || '';
      let nameExt = name + '.' + extension;

      let finalsize = (info && info.size) || size || '';
      let percentage = (loaded / finalsize) * 100 + '%';

      if (nameExt === '.') {
        nameExt = 'Soubor';
      }

      const style = {
        width: percentage,
      };

      return (
        <div className="status-bar-item" data-downloaded={percentage} key={element}>
          <div className="status-bar-item-status" style={style}></div>
          <div className="status-bar-item-info">
            {nameExt}
            <br />
            {fileSize(loaded)} / {fileSize(finalsize)}
          </div>
        </div>
      );
    });
  };

  render() {
    const { downloading, uploading, show } = this.props.StatusBar;

    const downloadingFiles = this.createItemsList(downloading);
    const uploadingFiles = this.createItemsList(uploading);

    const counter = downloadingFiles.length + uploadingFiles.length;

    return (
      <div className="status-bar">
        <div className="status-bar-toggler" ref={this.refToggler} onClick={(e) => this.toggleStatusBar(e)}>
          {counter > 0 && <span className="status-bar-toggler-counter">{counter}</span>}
        </div>

        {show && (
          <div className="status-bar-wrapper" ref={this.refWrapper}>
            {downloadingFiles.length === 0 && uploadingFiles.length === 0 && (
              <div className="status-bar-item-title">Právě nic nestahujete ani nenahráváte.</div>
            )}
            {downloadingFiles.length > 0 && (
              <>
                <div className="status-bar-item-title">Stahujete z disku:</div>
                {downloadingFiles}
              </>
            )}
            {downloadingFiles.length > 0 && uploadingFiles.length > 0 && (
              <div className="status-bar-item-divider"></div>
            )}
            {uploadingFiles.length > 0 && (
              <>
                <div className="status-bar-item-title">Nahráváte na disk:</div>
                {uploadingFiles}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(StatusBar);
