import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import PageLoading from '../../common/pageLoading';
import { getFileUrl } from '../../../actions/driveActions';

import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { isIOS } from '../../../utils/deviceDetection';
import { baseUrl } from '../../../api';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Preview extends Component {
  state = {
    isLoading: true,
    url: null,
    previewType: 'image',
    isFileLoad: false,
    pdfPages: 1,
    pdfActualPage: 1,
  };

  componentDidMount() {
    const urlToken = this?.props?.match?.params?.token;
    const { id: fileId, details } = this.props.drive.activeItem;
    const previewType = ['pdf'].includes(details.extension) ? 'pdf' : 'image';

    if (urlToken) {
      this.setState({
        url: `${baseUrl}file/${fileId}/share-link/view?token=${urlToken}`,
        previewType,
        isLoading: false,
      });
    } else {
      getFileUrl(fileId).then((url) => {
        this.setState({
          url,
          previewType,
          isLoading: false,
        });
      });
    }
  }

  handleFileLoad = (e) => {
    this.setState({
      isFileLoad: true,
    });
  };

  onDocumentLoadSuccess(numPages) {
    this.setState({
      pdfPages: numPages,
      pdfActualPage: 1,
      isFileLoad: true,
    });
  }

  handleChangePdfPage(pageId) {
    this.setState({
      pdfActualPage: pageId,
    });
  }

  render() {
    const { isLoading, previewType, url, isFileLoad, pdfPages, pdfActualPage } = this.state;

    if (isLoading) return <PageLoading />;

    return (
      <>
        {(previewType === 'image' || previewType === 'pdf') && (
          <div className={`popup-preview popup-preview--media popup-preview--${previewType}`}>
            {!isFileLoad && <PageLoading />}
            {previewType === 'image' && (
              <img
                alt=""
                className={isFileLoad ? 'is-loaded' : 'is-loading'}
                src={url}
                onLoad={(e) => this.handleFileLoad(e)}
              />
            )}
            {previewType === 'pdf' && !isIOS() && (
              <iframe
                title={previewType}
                width="100%"
                height="100%"
                className={isFileLoad ? 'is-loaded' : 'is-loading'}
                src={`${url}#toolbar=0`}
                onLoad={(e) => this.handleFileLoad(e)}
              />
            )}

            {previewType === 'pdf' && isIOS() && (
              <Document
                file={url}
                onLoadSuccess={({ numPages }) => this.onDocumentLoadSuccess(numPages)}
                scale={1}
                options={{
                  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                  cMapPacked: true,
                }}
              >
                <div className="pdf__controls">
                  {pdfActualPage > 1 && (
                    <div className="pdf__prev-page" onClick={() => this.handleChangePdfPage(pdfActualPage - 1)}>
                      Předchozí stránka
                    </div>
                  )}
                  {pdfActualPage < pdfPages && (
                    <div className="pdf__next-page" onClick={() => this.handleChangePdfPage(pdfActualPage + 1)}>
                      Následující stránka
                    </div>
                  )}
                </div>
                <Page
                  scale={0.5}
                  loading={'Please wait!'}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={1240}
                  pageNumber={this.state.pdfActualPage}
                />
              </Document>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  withRouter,
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(Preview);
