const initialState = {
  showContextMenu: false,
  persistantActiveItem: false,
  showDetailsPanel: false,
  driveTypeGrid: true,
  popupType: null,
  itemDetails: {},
  notifications: {},
  sorting: false,
  contextMenu: {
    show: false,
    item: null,
    x: 0,
    y: 0,
  },
};

const UIReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STATE':
      let obj = Object.assign({}, state, action.payload);
      return obj;

    case 'SHOW_CONTEXT_MENU':
      return {
        ...state,
        contextMenu: {
          show: true,
          item: action.payload.item,
          x: action.payload.x,
          y: action.payload.y,
        },
      };

    case 'HIDE_CONTEXT_MENU':
      return {
        ...state,
        contextMenu: {
          show: false,
          item: null,
          x: 0,
          y: 0,
        },
      };

    case 'SHOW_DETAILS_PANEL':
      return {
        ...state,
        showDetailsPanel: true,
      };

    case 'HIDE_DETAILS_PANEL':
      return {
        ...state,
        showDetailsPanel: false,
      };

    case 'TOGGLE_DETAILS_PANEL':
      return {
        ...state,
        showDetailsPanel: !state.showDetailsPanel,
      };

    case 'TOGGLE_DRIVE_LIST':
      return {
        ...state,
        driveTypeGrid: !state.driveTypeGrid,
      };

    case 'SET_PERSISTANT_ITEM':
      return {
        ...state,
        persistantActiveItem: true,
      };

    case 'REMOVE_PERSISTANT_ITEM':
      return {
        ...state,
        persistantActiveItem: false,
      };

    case 'CREATE_NOTIFICATION':
      state.notifications[Date.now()] = {
        uid: Date.now(),
        type: action.payload.type,
        message: action.payload.message,
      };

      return {
        ...state,
      };

    case 'DELETE_NOTIFICATION':
      delete state.notifications[action.uid];

      return {
        ...state,
      };

    case 'ENABLE_SORTING':
      return {
        ...state,
        sorting: action.group,
      };

    case 'DISABLE_SORTING':
      return {
        ...state,
        sorting: false,
      };

    default:
      return state;
  }
};

export default UIReducer;
