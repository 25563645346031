import { uploadFiles, fetchDriveStructure } from '../actions/driveActions';

export const onDragOver = (that, e) => {
  e.preventDefault();
};

export const onDragEnter = (that, e) => {
  e.preventDefault();
  e.stopPropagation();

  that.setState({ isDragOver: true });
};

export const onDragLeave = (that, e) => {
  e.preventDefault();
  e.stopPropagation();

  that.setState({ isDragOver: false });
};

export const onDrop = (that, e) => {
  e.preventDefault();
  e.stopPropagation();

  const { match, id, dispatch } = that.props;

  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    const files = e.dataTransfer.files;
    const data = new FormData();

    let pageId = id ? id : match.params.id;

    for (let i = 0; i < files.length; i++) {
      data.append('file[]', files[i]);
    }

    if (pageId) {
      data.append('folder_id', pageId);
    }

    e.dataTransfer.clearData();
    dispatch(uploadFiles(data)).then(() => {
      dispatch(fetchDriveStructure(match.params.id));
    });
  }

  document.body.classList.remove('is-dragging');
  that.setState({
    isDragOver: false,
  });
};

export const windowOut = (e) => {
  let result = false;
  if (e.clientY <= 0 || e.clientX <= 0 || e.clientX >= window.innerWidth || e.clientY >= window.innerHeight) {
    result = true;
    removeWindowDragClasses();
  }

  return result;
};

export const removeWindowDragClasses = () => {
  document.body.classList.remove('is-dragging');
  document.body.classList.remove('is-root-folder');
  document.body.classList.remove('is-quick_access-folder');
  document.body.classList.remove('is-sorting');
};
